import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiUsers, FiBell, FiHome, FiBook } from 'react-icons/fi';
import logoImg from '../../assets/logo.png';
import MenuAdmin from '../MenuAdmin';

import { Container, Content, Profile } from './styles';
import { useAuth } from '../../hooks/auth';

const Header: React.FC = () => {
  const { user, signOut } = useAuth();

  const handleLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Promise" />
        <nav>
          <div>
            <Link to="/dashboardContas">
              <FiHome size={28} />
              <h4>Início</h4>
            </Link>
          </div>
          <div>
            <Link to="/dashboardContas">
              <FiBook size={28} />
              <h4>Contas</h4>
            </Link>
          </div>
          <div>
            <Link to="/dashboardRede">
              <FiUsers size={28} />
              <h4>Rede</h4>
            </Link>
          </div>
          <div>
            <Link to="/dashboardNotificacoes">
              <FiBell size={28} />
              <h4>Notificações</h4>
            </Link>
          </div>
        </nav>
        <aside>
          {user.admin && <MenuAdmin />}
          <Profile>
            <div>
              <strong>{user.name}</strong>
              <Link to="/profile">Meu perfil</Link>
            </div>
            <button type="button" onClick={handleLogout} title="Sair">
              <img src={user.avatar_url} alt={user.name} />
            </button>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
};

export default Header;
