import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  background-color: #f1faee;
  border-radius: 10px;
  width: 400px;
  height: 440px;

  margin: auto 0;
  header {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid;
    border-bottom-color: #457b9d;
    h2 {
      margin-left: 10px;
      color: #1d3557;
    }
    svg {
      color: #1d3557;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  form {
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    h1 {
      margin-bottom: 24px;
      font-size: 20px;
      text-align: left;
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;
