import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FiKey,
  FiCalendar,
  FiDollarSign,
  FiEdit2,
  FiXSquare,
  FiGrid,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useRouteMatch } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ButtonRed from '../../../components/ButtonRed';
import Radio from '../../../components/Radio';
import Loading from '../../../components/Loading';
import IMovimento from '../../../models/IMovimento';

import { Container, Frame, Topbar, Content, ContentLoading } from './styles';
import Alert from '../../../components/Alert';
import currencyToNumber from '../../../utils/currencyToNumber';
import dateToDate from '../../../utils/dateToDate';
import { enumTipoCRUD } from '../../../models/TipoCRUD';
import Header from '../../../components/Header';
import { enumOperacaoMovimento } from '../../../models/IOperacaoMovimento';

interface EditMovimentacaoParams {
  conta_id: string;
  movimento_id: string;
}

const EditMovimentacao: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<EditMovimentacaoParams>();
  const formRef = useRef<FormHandles>(null);
  const [movimento, setMovimento] = useState<IMovimento>();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [loadingExclusao, setLoadingExclusao] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setLoading(true);
    api
      .get<IMovimento>('movimento', {
        params: {
          movimento_id: params.movimento_id,
        },
      })
      .then((response) => {
        setMovimento(response.data);
        formRef.current?.setFieldValue(
          'operacaoMovimento',
          response.data.operacaoMovimento,
        );
        setLoading(false);
      });
  }, [params.movimento_id]);

  const handleDelete = useCallback(async () => {
    setIsAlertVisible(false);
    setLoadingExclusao(true);
    try {
      const formData = {
        tipoCRUD: enumTipoCRUD.APAGAR,
        movimento_id: params.movimento_id,
        conta_id: params.conta_id,
        operacaoMovimento: enumOperacaoMovimento.CREDITADO,
        nome: 'delete',
        data: '01/01/2001',
        valor: '0',
      };
      await api.post('/movimento', formData);
      setLoadingExclusao(false);
      addToast({
        type: 'success',
        title: 'Movimentação',
        description: 'Movimentação excluída com sucesso !',
      });
      history.goBack();
    } catch (err) {
      setLoadingExclusao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão de movimentação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de movimentação !',
        description: 'Ocorreu um erro na exclusão da movimentação',
      });
    }
  }, [addToast, params, history]);

  const handleSubmit = useCallback(
    async (data: IMovimento) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          data: Yup.string().required('Data obrigatório'),
          valor: Yup.string().required('Valor obrigatório'),
          operacaoMovimento: Yup.string().required('Favor informar a operação'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          tipoCRUD: enumTipoCRUD.ALTERAR,
          movimento_id: params.movimento_id,
          nome: data.nome,
          data: dateToDate(data.data),
          valor: currencyToNumber(data.valor),
          operacaoMovimento: data.operacaoMovimento,
          conta_id: params.conta_id,
        };

        await api.post('/movimento', formData);
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Edição Movimentação',
          description: 'Movimentação alterada com sucesso !',
        });
        history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na alteração de movimentação',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na alteração de movimentação !',
          description: 'Ocorreu um erro na edição da movimentação',
        });
      }
    },
    [addToast, params, history],
  );

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiEdit2 size={24} />
            <h3>Edição Movimentação</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              operacaoMovimento: movimento?.operacaoMovimento,
              nome: movimento?.nome,
              data: movimento?.data,
              valor: movimento?.valor,
            }}
          >
            {loading ? (
              <ContentLoading>
                <Loading />
              </ContentLoading>
            ) : (
              <>
                <div>
                  <h4>Nome</h4>
                  <Input
                    name="nome"
                    icon={FiKey}
                    placeholder="Nome"
                    autoFocus
                  />
                </div>
                <div>
                  <h4>Data</h4>
                  <Input
                    name="data"
                    icon={FiCalendar}
                    mask="date"
                    placeholder="Data"
                  />
                </div>
                <div>
                  <h4>Valor</h4>
                  <Input
                    name="valor"
                    icon={FiDollarSign}
                    mask="currency"
                    placeholder="Valor"
                  />
                </div>
                <div>
                  <h4>Operação</h4>
                  <Radio
                    name="operacaoMovimento"
                    options={[
                      { id: 'CREDITADO', label: 'Crédito' },
                      { id: 'DEBITADO', label: 'Débito' },
                    ]}
                  />
                </div>

                <div>
                  <Button type="submit">Salvar Alterações</Button>
                </div>
              </>
            )}
          </Form>
        </Content>
      </Frame>
      <Frame>
        <Topbar>
          <div>
            <FiGrid size={24} />
            <h3>Outras Opções</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        {loadingExclusao ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            <ButtonRed type="button" onClick={() => setIsAlertVisible(true)}>
              Excluir Movimentação
            </ButtonRed>
          </Content>
        )}
      </Frame>
      {isAlertVisible ? (
        <Alert
          mensagem="Deseja realmente excluir esta movimentação ?"
          onClose={() => setIsAlertVisible(false)}
          onConfirm={handleDelete}
        />
      ) : null}
    </Container>
  );
};

export default EditMovimentacao;
