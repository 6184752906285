import React, { useState, useCallback } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Container, Badge, MenuList, Menu, Scroll } from './styles';

const MenuAdmin: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <Container>
      <Badge onClick={handleToggleVisible}>
        <FiMenu size={30} />
      </Badge>
      <MenuList visible={visible}>
        <Scroll>
          <Menu>
            <p>
              <Link to="/tabelasAtualizacao">Tabelas de Atualização</Link>
            </p>
          </Menu>
        </Scroll>
      </MenuList>
    </Container>
  );
};

export default MenuAdmin;
