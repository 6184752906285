import React, { useCallback, useRef, useState } from 'react';
import { FiEdit, FiEdit2, FiMail, FiXSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';

import { Container, Content, Frame, Topbar } from './styles';
import Header from '../../../components/Header';

interface NovoRelacionamentoFormData {
  nome: string;
  emailConvidado: string;
  textoConvite: string;
  tabela_id: string;
}

const NovoRelacionamento: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: NovoRelacionamentoFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          emailConvidado: Yup.string().email().required('Email obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          emailConvidado: data.emailConvidado,
          textoConvite:
            data.textoConvite === ''
              ? 'Convite para formação de rede'
              : data.textoConvite,
        };
        if (!loading) {
          await api.post('/solicitacaoRede', formData);
          setLoading(false);
          addToast({
            type: 'success',
            title: 'Nova Rede',
            description:
              'Solicitação realizada com sucesso. Aguarde a outra pessoa aceitar o convite  !',
          });
          history.goBack();
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na solicitação de criação de rede',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação de criação de rede !',
          description: 'Ocorreu um erro na solicitação de criação de rede',
        });
      }
    },
    [addToast, history, loading],
  );

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiEdit2 size={24} />
            <h3>Novo Convite</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <h4>E-mail da Pessoa Convidada</h4>
              <Input
                name="emailConvidado"
                icon={FiMail}
                placeholder="Informe o e-mail"
                autoFocus
              />
            </div>
            <div>
              <h4>Texto do Convite</h4>
              <Textarea
                name="textoConvite"
                icon={FiEdit}
                placeholder="Texto do Convite"
              />
            </div>
            <Button
              type="submit"
              loading={loading}
              style={{ marginTop: '20px' }}
            >
              Enviar Convite
            </Button>
          </Form>
        </Content>
      </Frame>
    </Container>
  );
};

export default NovoRelacionamento;
