import React from 'react';

import { Container } from './styles';

interface IQuadroResumo {
  titulo: string;
  valor?: string;
  sinal: string | undefined;
}

const QuadroResumo: React.FC<IQuadroResumo> = ({
  sinal = '+',
  titulo,
  valor,
}) => {
  return (
    <Container sinal={sinal}>
      <h2>{titulo}</h2>
      <h4>{valor}</h4>
    </Container>
  );
};

export default QuadroResumo;
