import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/usuario/SignIn';
import SignUp from '../pages/usuario/SignUp';
import ForgotPassword from '../pages/usuario/ForgotPassword';
import ResetPassword from '../pages/usuario/ResetPassword';
import HabilitaAcesso from '../pages/usuario/HabilitaAcesso';

import Profile from '../pages/usuario/Profile';
import DashboardRede from '../pages/rede/DashboardRede';
import NovoRelacionamento from '../pages/rede/NovoRelacionamento';
import dashboardContas from '../pages/contas/DashboardContas';
import DashboardContaCompartilhada from '../pages/contas/DashboardContaCompartilhada';
import DashboardNotificacoes from '../pages/notificacoes/DashboardNotificacoes';
import ContaIndividual from '../pages/contas/ContaIndividual';
import NovaConta from '../pages/contas/NovaConta';
import NovaContaCompartilhada from '../pages/contas/NovaContaCompartilhada';
import EditContaIndividual from '../pages/contas/EditContaIndividual';
import NovaMovimentacao from '../pages/movimentacoes/NovaMovimentacao';
import SolicitarMovimentacao from '../pages/movimentacoes/SolicitaMovimentacao';
import EditMovimentacao from '../pages/movimentacoes/EditMovimentacao';
import ContaCompartilhada from '../pages/contas/ContaCompartilhada';
import TabelasAtualizacao from '../pages/admin/TabelasAtualizacao';
import Indice from '../pages/admin/Indice';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/habilita-acesso" component={HabilitaAcesso} />

    <Route path="/dashboardRede" component={DashboardRede} isPrivate />
    <Route
      path="/dashboardNotificacoes"
      component={DashboardNotificacoes}
      isPrivate
    />
    <Route path="/dashboardContas" component={dashboardContas} isPrivate />
    <Route
      path="/dashboardContaCompartilhada/:rede_id"
      component={DashboardContaCompartilhada}
      isPrivate
    />
    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/novaConta" component={NovaConta} isPrivate />
    <Route
      path="/novoRelacionamento"
      component={NovoRelacionamento}
      isPrivate
    />
    <Route
      path="/contaIndividual/:conta_id"
      component={ContaIndividual}
      isPrivate
    />
    <Route
      path="/editContaIndividual/:conta_id"
      component={EditContaIndividual}
      isPrivate
    />
    <Route
      path="/novaContaCompartilhada/:rede_id"
      component={NovaContaCompartilhada}
      isPrivate
    />
    <Route
      path="/novaContaCompartilhada"
      component={NovaContaCompartilhada}
      isPrivate
    />
    <Route
      path="/novaMovimentacao/:conta_id"
      component={NovaMovimentacao}
      isPrivate
    />
    <Route
      path="/solicitaMovimentacao/:conta_id"
      component={SolicitarMovimentacao}
      isPrivate
    />
    <Route
      path="/editMovimentacao/:conta_id/:movimento_id"
      component={EditMovimentacao}
      isPrivate
    />
    <Route
      path="/contaCompartilhada/:conta_id"
      component={ContaCompartilhada}
      isPrivate
    />
    <Route
      path="/tabelasAtualizacao"
      component={TabelasAtualizacao}
      isPrivate
    />
    <Route path="/indice/:tabela_id" component={Indice} isPrivate />
  </Switch>
);

export default Routes;
