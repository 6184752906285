/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import { Container } from './styles';
import Header from '../../../components/Header';
import ConvitesRecebidos from '../../../components/ConvitesRecebidos';

const DashboardNotificacoes: React.FC = () => {
  return (
    <Container>
      <Header />
      <ConvitesRecebidos />
    </Container>
  );
};

export default DashboardNotificacoes;
