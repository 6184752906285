import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { lighten } from 'polished';

interface MenuListProps {
  visible: boolean;
}

export const Container = styled.div`
  position: relative;
`;
export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-right: 10px;
`;

export const MenuList = styled.div<MenuListProps>`
  position: absolute;
  width: 200px;
  left: calc(50% - 100px);
  top: calc(100% + 30px);
  background: #457b9d;
  border-radius: 4px;
  padding: 15px 5px;
  display: ${(props) => (props.visible ? 'block' : 'none')};

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 20px);
    top: -20px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #457b9d;
  }
`;

export const Scroll = styled(PerfectScrollbar)`
  max-height: 200px;
  padding: 5px 15px;
`;

export const Menu = styled.div`
  color: #f1faee;
  & + div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  p {
    font-size: 13px;
    line-height: 18px;
    a {
      color: #eee;
      text-decoration: none;
    }
  }
  time {
    font-size: 12px;
    opacity: 0.6;
  }
  button {
    font-size: 12px;
    border: 0;
    background: none;
    color: ${lighten(0.2, '#457b9d')};
    padding: 0 5px;
    margin: 0 5px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
`;
