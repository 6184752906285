export default function dateToDate(dateString: string): Date {
  const dateParts = dateString.split('/');

  const dateObject = new Date(
    +dateParts[2],
    Number(dateParts[1]) - 1,
    +dateParts[0],
  );

  return dateObject;
}
