/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState, useEffect } from 'react';
import {
  FiAlertCircle,
  FiClock,
  FiPlusCircle,
  FiUserCheck,
  FiUsers,
  FiUserX,
} from 'react-icons/fi';
import { Link, useRouteMatch } from 'react-router-dom';
import QuadroContaCompartilhada from '../../../components/QuadroContaCompartilhada';
import {
  Container,
  Frame,
  Topbar,
  Content,
  ContentLoading,
  ContaContainer,
} from './styles';
import Header from '../../../components/Header';
import Alert from '../../../components/Alert';
import api from '../../../services/api';
import IContaCompartilhada from '../../../models/IContaCompartilhada';
import Loading from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { TableContainer } from '../../../components/Table';
import ISolicitacaoConta from '../../../models/ISolicitacaoConta';
import IDetalheRede from '../../../models/IDetalheRede';

interface ContaCompartilhadaParams {
  rede_id: string;
}

const DashboardContaCompartilhada: React.FC = () => {
  const { params } = useRouteMatch<ContaCompartilhadaParams>();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAlertRejeitaVisible, setIsAlertRejeitaVisible] = useState(false);
  const [rede, setRede] = useState<IDetalheRede>();
  const [contas, setContas] = useState<IContaCompartilhada[]>([]);
  const [solicitacoesPendentes, setSolicitacoesPendentes] = useState<
    ISolicitacaoConta[]
  >([]);
  const [solicitacoesRecebidas, setSolicitacoesRecebidas] = useState<
    ISolicitacaoConta[]
  >([]);
  const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState<
    string
  >();
  const [loadingContas, setLoadingContas] = useState(true);
  const [loadingSolicitacao, setLoadingSolicitacao] = useState(true);
  const { addToast } = useToast();
  const { signOut, user } = useAuth();

  const listaRede = useCallback((): void => {
    api
      .get<IDetalheRede>('/rede', {
        params: {
          rede_id: params.rede_id,
        },
      })
      .then((response) => {
        setRede(response.data);
      })
      .catch((err) => {
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de rede',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de rede',
          description: 'Ocorreu um erro na listagem de rede',
        });
      });
  }, [addToast, signOut, params.rede_id]);

  const listaContas = useCallback((): void => {
    api
      .get<IContaCompartilhada[]>('/contas', {
        params: {
          tipoConta: 'COMPARTILHADA',
          rede_id: params.rede_id,
        },
      })
      .then((response) => {
        setContas(response.data);
        setLoadingContas(false);
      })
      .catch((err) => {
        setLoadingContas(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de contas',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de contas',
          description: 'Ocorreu um erro na listagem de contas',
        });
      });
  }, [addToast, signOut, params.rede_id]);

  useEffect(() => listaRede(), [listaRede, addToast]);
  useEffect(() => listaContas(), [listaContas, addToast]);

  const listaSolicitacoes = useCallback((): void => {
    const solPendente: ISolicitacaoConta[] = [];
    const solRecebido: ISolicitacaoConta[] = [];
    api
      .get<ISolicitacaoConta[]>('/solicitacaoConta', {
        params: {
          rede_id: params.rede_id,
          situacao: 'PENDENTE',
        },
      })
      .then((response) => {
        const solicitacoes = response.data;
        solicitacoes.forEach((solicitacao) => {
          if (solicitacao.user_id === user.id) {
            solPendente.push(solicitacao);
          } else {
            solRecebido.push(solicitacao);
          }
        });
        setSolicitacoesPendentes(solPendente);
        setSolicitacoesRecebidas(solRecebido);
        setLoadingSolicitacao(false);
      })
      .catch((err) => {
        setLoadingSolicitacao(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de solicitações pendentes',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de solicitações pendentes',
          description: 'Ocorreu um erro na listagem de solicitações pendentes',
        });
      });
  }, [addToast, signOut, user.id, params.rede_id]);

  useEffect(() => listaSolicitacoes(), [listaSolicitacoes, addToast]);

  const handleDeleteSolicitacao = useCallback(async () => {
    try {
      setIsAlertVisible(false);
      setLoadingSolicitacao(true);
      await api.delete('/solicitacaoConta', {
        params: {
          solicitacao_id: solicitacaoSelecionada,
        },
      });
      setLoadingSolicitacao(false);
      listaSolicitacoes();
      addToast({
        type: 'success',
        title: 'Solicitação',
        description: 'Solicitação excluída com sucesso !',
      });
    } catch (err) {
      setLoadingSolicitacao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão de solicitação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de solicitação !',
        description: 'Ocorreu um erro na exclusão da solicitação',
      });
    }
  }, [addToast, listaSolicitacoes, solicitacaoSelecionada]);

  const handleAceitaSolicitacao = useCallback(
    async (solicitacao_id) => {
      try {
        setLoadingSolicitacao(true);
        await api.get('/solicitacaoConta/aceitaSolicitacao', {
          params: {
            solicitacao_id,
          },
        });
        setLoadingSolicitacao(false);
        listaSolicitacoes();
        listaContas();
        addToast({
          type: 'success',
          title: 'Solicitação',
          description: 'Conta criada com sucesso !',
        });
      } catch (err) {
        setLoadingSolicitacao(false);
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na solicitação',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação !',
          description: 'Ocorreu um erro na solicitação',
        });
      }
    },
    [addToast, listaSolicitacoes, listaContas],
  );

  const handleRejeitaSolicitacao = useCallback(async () => {
    try {
      setIsAlertRejeitaVisible(false);
      setLoadingSolicitacao(true);
      await api.get('/solicitacaoConta/rejeitaSolicitacao', {
        params: {
          solicitacao_id: solicitacaoSelecionada,
        },
      });
      setLoadingSolicitacao(false);
      listaSolicitacoes();
      addToast({
        type: 'success',
        title: 'Solicitação',
        description: 'Solicitação rejeitada com sucesso !',
      });
    } catch (err) {
      setLoadingSolicitacao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na solicitação !',
        description: 'Ocorreu um erro na solicitação',
      });
    }
  }, [addToast, listaSolicitacoes, solicitacaoSelecionada]);

  return (
    <Container>
      <Header />
      {solicitacoesRecebidas.length > 0 && (
        <Frame>
          <Topbar>
            <div>
              <FiAlertCircle size={24} />
              <h3>Solicitações Recebidas</h3>
            </div>
          </Topbar>
          {loadingSolicitacao ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Content>
              <TableContainer>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Proponente</th>
                    <th>Conta</th>
                    <th>Convite</th>
                    <th>Correção</th>
                    <th>Recusar</th>
                    <th>Aceitar</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitacoesRecebidas.map((solicitacao) => (
                    <tr key={solicitacao.id}>
                      <td>{solicitacao.dataConvite}</td>
                      <td>{solicitacao.user.name}</td>
                      <td>{solicitacao.nome}</td>
                      <td>{solicitacao.textoConvite}</td>

                      <td>
                        {solicitacao.tabelaAtualizacao === null
                          ? 'Sem Correção'
                          : solicitacao.tabelaAtualizacao.nome}
                      </td>
                      <td>
                        <FiUserX
                          size={24}
                          title="Não, obrigado. Quero recusar este convite."
                          style={{ cursor: 'pointer', marginRight: '20px' }}
                          onClick={() => {
                            setIsAlertRejeitaVisible(true);
                            setSolicitacaoSelecionada(solicitacao.id);
                          }}
                        />
                      </td>
                      <td>
                        <FiUserCheck
                          size={24}
                          title="Aceitar este convite e criar a Conta!"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleAceitaSolicitacao(solicitacao.id)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            </Content>
          )}
        </Frame>
      )}
      <Frame>
        <Topbar>
          <div>
            <FiUsers size={24} />
            <h3>Contas com {rede?.nomeParceiro}</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to={`/novaContaCompartilhada/${params.rede_id}`}
          >
            <FiPlusCircle
              size={26}
              title="Propor Conta Compartilhada"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        {loadingContas ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            {!contas.length ? (
              <h2>Você não possui nenhuma conta com {rede?.nomeParceiro} </h2>
            ) : (
              <ContaContainer>
                {contas.map((conta) => (
                  <QuadroContaCompartilhada
                    key={conta.id}
                    id={conta.id}
                    nome={conta.nomeConta}
                    creditos={conta.totalCreditos}
                    debitos={conta.totalDebitos}
                    correcao={conta.totalCorrecao}
                    saldo={conta.saldo}
                    sinal={conta.sinalSaldo}
                    dataSaldo={conta.dataSaldo}
                    avatar_url={conta.avatarParceiro}
                    convidado={conta.nomeParceiro}
                  />
                ))}
              </ContaContainer>
            )}
          </Content>
        )}
      </Frame>

      {solicitacoesPendentes.length > 0 && (
        <Frame>
          <Topbar>
            <div>
              <FiClock size={24} />
              <h3>Solicitações Pendentes</h3>
            </div>
          </Topbar>
          {loadingSolicitacao ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Content>
              <TableContainer>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Conta</th>
                    <th>Correção</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitacoesPendentes.map((solicitacao) => (
                    <tr key={solicitacao.id}>
                      <td>{solicitacao.dataConvite}</td>
                      <td>{solicitacao.nome}</td>
                      <td>
                        {solicitacao.tabelaAtualizacao === null
                          ? 'Sem Correção'
                          : solicitacao.tabelaAtualizacao.nome}
                      </td>
                      <td>
                        <FiUserX
                          size={24}
                          title="Eu desisti ! Quero cancelar este convite."
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsAlertVisible(true);
                            setSolicitacaoSelecionada(solicitacao.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            </Content>
          )}
        </Frame>
      )}
      {isAlertVisible ? (
        <Alert
          mensagem="Deseja realmente apagar esse convite ?"
          onClose={() => setIsAlertVisible(false)}
          onConfirm={handleDeleteSolicitacao}
        />
      ) : null}
      {isAlertRejeitaVisible ? (
        <Alert
          mensagem="Deseja realmente recusar esse convite ?"
          onClose={() => setIsAlertRejeitaVisible(false)}
          onConfirm={handleRejeitaSolicitacao}
        />
      ) : null}
    </Container>
  );
};

export default DashboardContaCompartilhada;
