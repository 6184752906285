import styled from 'styled-components';

interface ContainerProps {
  sinal: string;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 60px;
  border-radius: 6px;
  margin-left: 4px;
  color: ${({ sinal }: ContainerProps): string =>
    sinal === '+' ? ' #F1FAEE' : '#F1FAEE'};
  background: ${({ sinal }: ContainerProps): string =>
    sinal === '-' ? ' #e63946' : '#457b9d'};
  h2 {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 18px;
    font-style: bold;
  }
  h4 {
    text-align: right;
    margin-top: 5px;
    margin-right: 10px;
    font-size: 16px;
  }
`;
