/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState, useEffect } from 'react';
import { FiAlertCircle, FiUserCheck, FiUserX } from 'react-icons/fi';
import Alert from '../Alert';
import { Container, Frame, Topbar, Content, ContentLoading } from './styles';
import api from '../../services/api';
import Loading from '../Loading';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { TableContainer } from '../Table';
import ISolicitacao from '../../models/ISolicitacaoRede';

const ConvitesRecebidos: React.FC = () => {
  const [isAlertRejeitaVisible, setIsAlertRejeitaVisible] = useState(false);
  const [solicitacoesRecebidas, setSolicitacoesRecebidas] = useState<
    ISolicitacao[]
  >([]);
  const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState<
    string
  >();
  const [loadingSolicitacao, setLoadingSolicitacao] = useState(true);
  const { addToast } = useToast();
  const { signOut, user } = useAuth();

  const listaSolicitacoes = useCallback((): void => {
    const solRecebido: ISolicitacao[] = [];
    api
      .get<ISolicitacao[]>('/solicitacaoRede', {
        params: {
          situacao: 'PENDENTE',
        },
      })
      .then((response) => {
        const solicitacoes = response.data;
        solicitacoes.forEach((solicitacao) => {
          if (solicitacao.user_id !== user.id) {
            solRecebido.push(solicitacao);
          }
        });
        setSolicitacoesRecebidas(solRecebido);
        setLoadingSolicitacao(false);
      })
      .catch((err) => {
        setLoadingSolicitacao(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de solicitações pendentes',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de solicitações pendentes',
          description: 'Ocorreu um erro na listagem de solicitações pendentes',
        });
      });
  }, [addToast, signOut, user.id]);

  useEffect(() => listaSolicitacoes(), [listaSolicitacoes, addToast]);

  const handleAceitaSolicitacao = useCallback(
    async (solicitacao_id) => {
      try {
        setLoadingSolicitacao(true);
        await api.get('/solicitacaoRede/aceitaSolicitacao', {
          params: {
            solicitacao_id,
          },
        });
        setLoadingSolicitacao(false);
        listaSolicitacoes();
        addToast({
          type: 'success',
          title: 'Solicitação',
          description: 'Rede criada com sucesso !',
        });
      } catch (err) {
        setLoadingSolicitacao(false);
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na solicitação',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação !',
          description: 'Ocorreu um erro na solicitação',
        });
      }
    },
    [addToast, listaSolicitacoes],
  );

  const handleRejeitaSolicitacao = useCallback(async () => {
    try {
      setIsAlertRejeitaVisible(false);
      setLoadingSolicitacao(true);
      await api.get('/solicitacaoRede/rejeitaSolicitacao', {
        params: {
          solicitacao_id: solicitacaoSelecionada,
        },
      });
      setLoadingSolicitacao(false);
      listaSolicitacoes();
      addToast({
        type: 'success',
        title: 'Solicitação',
        description: 'Solicitação rejeitada com sucesso !',
      });
    } catch (err) {
      setLoadingSolicitacao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na solicitação !',
        description: 'Ocorreu um erro na solicitação',
      });
    }
  }, [addToast, listaSolicitacoes, solicitacaoSelecionada]);

  return (
    <Container>
      <Frame>
        <Topbar>
          <div>
            <FiAlertCircle size={24} />
            <h3>Convites Recebidos</h3>
          </div>
        </Topbar>
        {loadingSolicitacao ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            {!solicitacoesRecebidas.length ? (
              <div>
                <h3>
                  Você não possui nenhum convite pendente de recebimento !
                </h3>
              </div>
            ) : (
              <TableContainer>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Solicitante</th>
                    <th>Convite</th>
                    <th>Recusar</th>
                    <th>Aceitar</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitacoesRecebidas.map((solicitacao) => (
                    <tr key={solicitacao.id}>
                      <td>{solicitacao.dataConvite}</td>
                      <td>
                        <div>
                          <img
                            style={{ width: '50px', height: '50px' }}
                            src={solicitacao.user.avatar_url}
                            alt={solicitacao.user.name}
                          />
                          {solicitacao.user.name}
                        </div>
                      </td>
                      <td>{solicitacao.textoConvite}</td>

                      <td>
                        <FiUserX
                          size={24}
                          title="Não, obrigado. Quero recusar este convite."
                          style={{ cursor: 'pointer', marginRight: '20px' }}
                          onClick={() => {
                            setIsAlertRejeitaVisible(true);
                            setSolicitacaoSelecionada(solicitacao.id);
                          }}
                        />
                      </td>
                      <td>
                        <FiUserCheck
                          size={24}
                          title="Aceitar este convite e criar a Conta!"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleAceitaSolicitacao(solicitacao.id)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            )}
          </Content>
        )}
      </Frame>

      {isAlertRejeitaVisible ? (
        <Alert
          mensagem="Deseja realmente recusar esse convite ?"
          onClose={() => setIsAlertRejeitaVisible(false)}
          onConfirm={handleRejeitaSolicitacao}
        />
      ) : null}
    </Container>
  );
};

export default ConvitesRecebidos;
