import styled from 'styled-components';

const TableContainer = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  thead th {
    text-align: left;
    color: #1d3557;
    font-size: 16px;
    padding: 6px 15px 0;
    &:last-child {
      text-align: right;
    }
  }

  tbody td:last-child {
    border-radius: 0 0.2em 0.2em 0;
  }

  tbody td:first-child {
    border-radius: 0.2em 0 0 0.2em;
  }

  tbody td {
    color: #1d3557;
    background: #f1faee;
    height: 40px;
    padding: 6px 15px;
    font-size: 16px;
    &:last-child {
      text-align: right;
    }
    div {
      display: flex;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }
`;
export default TableContainer;
