import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiKey, FiXSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { Container, Frame, Content, Topbar } from './styles';
import ICombo from '../../../models/ICombo';
import Header from '../../../components/Header';

interface NovaContaFormData {
  nome: string;
  tabela_id: string;
}

const NovaConta: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [tabelas, setTabelas] = useState<ICombo[]>([]);

  useEffect(() => {
    api
      .get<ICombo[]>('combo', {
        params: {
          nome_combo: 'tabelas_atualizacao',
        },
      })
      .then((response) => {
        setTabelas(response.data);
      });
  }, []);

  const handleSubmit = useCallback(
    async (data: NovaContaFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          tabela_id: Yup.string().required('Informe a correção'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          nome: data.nome,
          tabela_id: data.tabela_id,
        };
        if (!loading) {
          await api.post('/contaIndividual', formData).then((response) => {
            const idConta = response.data;
            history.push(`/contaIndividual/${idConta}`);
          });
          setLoading(false);
          addToast({
            type: 'success',
            title: 'Nova Conta',
            description: 'Conta criada com sucesso !',
          });
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na criação de conta',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na criação de conta !',
          description: 'Ocorreu um erro na criação da conta',
        });
      }
    },
    [addToast, history, loading],
  );

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiEdit2 size={24} />
            <h3>Nova Conta Individual</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <h4>Nome</h4>
              <Input name="nome" icon={FiKey} placeholder="Nome" autoFocus />
            </div>
            <div>
              <h4>Correção</h4>
              <Select
                name="tabela_id"
                placeholder="Correção..."
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#F1FAEE',
                    borderColor: '#457B9D',
                    borderWidth: '2px',
                    height: '56px',
                  }),
                }}
                options={tabelas}
              />
            </div>

            <Button
              type="submit"
              style={{ marginTop: '10px' }}
              loading={loading}
            >
              Criar Conta
            </Button>
          </Form>
        </Content>
      </Frame>
    </Container>
  );
};

export default NovaConta;
