import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit, FiEdit2, FiKey, FiXSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useRouteMatch } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Textarea from '../../../components/Textarea';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { Container, Frame, Topbar, Content } from './styles';
import ICombo from '../../../models/ICombo';
import Header from '../../../components/Header';
import IDetalheRede from '../../../models/IDetalheRede';

interface NovaContaCompartilhadaParams {
  rede_id: string;
}

interface NovaContaCompartilhadaFormData {
  idPessoa: string;
  nome: string;
  textoConvite: string;
  tabela_id: string;
}

const NovaContaCompartilhada: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<NovaContaCompartilhadaParams>();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [tabelas, setTabelas] = useState<ICombo[]>([]);
  const [pessoas, setPessoas] = useState<ICombo[]>([]);

  useEffect(() => {
    api
      .get<ICombo[]>('combo', {
        params: {
          nome_combo: 'tabelas_atualizacao',
        },
      })
      .then((response) => {
        setTabelas(response.data);
      });
  }, []);

  useEffect(() => {
    api.get<IDetalheRede[]>('/rede', {}).then((response) => {
      const rp = response.data.map<ICombo>((detalhe) => ({
        value: detalhe.id,
        label: detalhe.nomeParceiro,
      }));
      const p = rp.filter((pessoa) => pessoa.value === params.rede_id);
      formRef.current?.setFieldValue('idPessoa', p[0]);
      setPessoas(rp);
    });
  }, [params.rede_id]);

  const handleSubmit = useCallback(
    async (data: NovaContaCompartilhadaFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          idPessoa: Yup.string().required('Favor informar a pessoa'),
          nome: Yup.string().required('Nome obrigatório'),
          textoConvite: Yup.string().required('Preencha o convite'),
          tabela_id: Yup.string().required('Informe a correção'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          rede_id: data.idPessoa,
          nome: data.nome,
          textoConvite: data.textoConvite,
          tabela_id: data.tabela_id,
        };
        if (!loading) {
          await api.post('/solicitacaoConta', formData);
          setLoading(false);
          addToast({
            type: 'success',
            title: 'Nova Conta Compartilhada',
            description:
              'Solicitação realizada com sucesso. Aguarde a outra pessoa aceitar a proposta !',
          });
          history.goBack();
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na solicitação de criação de conta',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação de criação de conta !',
          description: 'Ocorreu um erro na solicitação de criação de conta',
        });
      }
    },
    [addToast, loading, history],
  );

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiEdit2 size={24} />
            <h3>Propor Conta Compartilhada</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              nome: 'Conta',
              textoConvite: 'Convite',
            }}
          >
            <div>
              <h4>Pessoa</h4>
              <Select
                name="idPessoa"
                placeholder="Pessoa..."
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#F1FAEE',
                    borderColor: '#457B9D',
                    borderWidth: '2px',
                    height: '56px',
                  }),
                }}
                options={pessoas}
              />
            </div>
            <div>
              <h4>Nome da Conta</h4>
              <Input name="nome" icon={FiKey} />
            </div>
            <div>
              <h4>Texto do Convite</h4>
              <Textarea name="textoConvite" icon={FiEdit} />
            </div>
            <div>
              <h4>Proposta de Correção</h4>
              <Select
                name="tabela_id"
                placeholder="Proposta de Correção..."
                autoFocus
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#F1FAEE',
                    borderColor: '#457B9D',
                    borderWidth: '2px',
                    height: '56px',
                  }),
                }}
                options={tabelas}
              />
            </div>

            <Button type="submit" loading={loading}>
              Enviar Solicitação de Conta
            </Button>
          </Form>
        </Content>
      </Frame>
    </Container>
  );
};

export default NovaContaCompartilhada;
