import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #F1FAEE;
  border-radius: 5px;

  border: 2px solid #457B9D;
  color: #666360;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }
  ${(props) =>
    props.isErrored &&
    css`
      border-color: #e63946;
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: #1d3557;
      border-color: #1d3557;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #1d3557;
    `}



  textarea {
    flex: 1;
    background: transparent;
    border: 0;
    color: #1D3557;
    resize: none;

    &::placeholder {
      color: #666360;
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin-right: 0;
  }
  span {
    background: #e63946;
    color: #fff;
    &::before {
      border-color: #e63946 transparent;
    }
  }
`;
