import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiGrid, FiKey, FiXSquare } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useRouteMatch } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import Loading from '../../../components/Loading';

import { Container, Frame, Topbar, Content, ContentLoading } from './styles';
import Alert from '../../../components/Alert';
import ICombo from '../../../models/ICombo';
import Header from '../../../components/Header';
import ButtonRed from '../../../components/ButtonRed';
import IContaIndividual from '../../../models/IContaIndividual';

interface ContaParams {
  conta_id: string;
}

interface EditContaIndividualFormData {
  nomeConta: string;
  idTabela: string;
}

const EditContaIndividual: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<ContaParams>();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [loadingExclusao, setLoadingExclusao] = useState(false);
  const { addToast } = useToast();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [conta, setConta] = useState<IContaIndividual>();
  const [tabelas, setTabelas] = useState<ICombo[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get<IContaIndividual>('contaIndividual', {
        params: {
          conta_id: params.conta_id,
        },
      })
      .then((response) => {
        setConta(response.data);
        setLoading(false);
      });
  }, [params.conta_id]);

  useEffect(() => {
    api
      .get<ICombo[]>('combo', {
        params: {
          nome_combo: 'tabelas_atualizacao',
        },
      })
      .then((response) => {
        setTabelas(response.data);
      });
  }, []);

  const handleEncerrarConta = useCallback(async () => {
    setIsAlertVisible(false);
    setLoadingExclusao(true);
    try {
      await api.delete('/contas', {
        params: {
          conta_id: params.conta_id,
        },
      });
      setLoadingExclusao(false);

      addToast({
        type: 'success',
        title: 'Exclusão de Conta',
        description: 'Conta excluída com sucesso !',
      });
      history.push('/dashboardContas');
    } catch (err) {
      setLoadingExclusao(false);

      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão da conta',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de conta !',
        description: 'Ocorreu um erro na exclusão da conta',
      });
    }
  }, [addToast, params.conta_id, history]);

  const handleSubmit = useCallback(
    async (data: EditContaIndividualFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nomeConta: Yup.string().required('Nome obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          conta_id: params.conta_id,
          nome: data.nomeConta,
          tabela_id: data.idTabela,
        };

        await api.put('/contaIndividual', formData);
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Edição de Conta',
          description: 'Conta alterada com sucesso !',
        });
        history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na alteração de conta',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na alteração de conta !',
          description: 'Ocorreu um erro na edição da conta',
        });
      }
    },
    [addToast, params.conta_id, history],
  );

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiEdit2 size={24} />
            <h3>Alteração de Conta</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              nomeConta: conta?.nomeConta,
              idTabela: { value: conta?.idTabela, label: conta?.nomeTabela },
            }}
          >
            {loading ? (
              <ContentLoading>
                <Loading />
              </ContentLoading>
            ) : (
              <>
                <div>
                  <h4>Nome</h4>
                  <Input
                    name="nomeConta"
                    icon={FiKey}
                    placeholder="Nome"
                    autoFocus
                  />
                </div>
                <div>
                  <h4>Correção</h4>
                  <Select
                    name="idTabela"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: '#F1FAEE',
                        borderColor: '#457B9D',
                        borderWidth: '2px',
                        height: '56px',
                      }),
                    }}
                    options={tabelas}
                  />
                </div>

                <div>
                  <Button type="submit">Salvar Alterações</Button>
                </div>
              </>
            )}
          </Form>
        </Content>
      </Frame>
      <Frame>
        <Topbar>
          <div>
            <FiGrid size={24} />
            <h3>Outras Opções</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>
        {loadingExclusao ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            <ButtonRed type="button" onClick={() => setIsAlertVisible(true)}>
              Excluir Conta
            </ButtonRed>
          </Content>
        )}
      </Frame>
      {isAlertVisible ? (
        <Alert
          mensagem="Deseja realmente excluir esta conta ?"
          onClose={() => setIsAlertVisible(false)}
          onConfirm={handleEncerrarConta}
        />
      ) : null}
    </Container>
  );
};

export default EditContaIndividual;
