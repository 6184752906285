import styled from 'styled-components';

interface ContainerProps {
  sinal: string;
}
export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  height: 200px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.5);
`;

export const Titulo = styled.div<ContainerProps>`
  display: flex;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  margin-right: 4px;
  border-bottom: ${({ sinal }: ContainerProps): string =>
    sinal === '-' ? '2px solid #e63946' : '2px solid #457b9d'};
  h2 {
    text-align: left;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 16px;
    flex: 1;
  }
`;
export const Miolo = styled.div`
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      text-align: left;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 16px;
    }
    h4 {
      text-align: right;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
`;
export const Rodape = styled.div`
  margin-bottom: 10px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      text-align: left;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 14px;
    }
    h4 {
      text-align: right;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
`;
