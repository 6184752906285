/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
import axios, { AxiosError } from 'axios';
import IUser from '../models/IUser';

let isRefreshing = false;
let failedRequestsQueue: {
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError<any>) => void;
}[] = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data.code === 'token_expired') {
        const userLocalJson = localStorage.getItem('@Promise:user');
        if (userLocalJson) {
          const userLocal = JSON.parse(userLocalJson) as IUser;
          const originalConfig = error.config;
          if (!isRefreshing) {
            isRefreshing = true;
            api
              .post('/sessions/refresh', {
                refreshToken: userLocal.refreshToken,
              })
              .then((response) => {
                const { user, token } = response.data;
                localStorage.setItem('@Promise:user', JSON.stringify(user));
                localStorage.setItem('@Promise:token', token);
                api.defaults.headers['authorization'] = `Bearer ${token}`;
                failedRequestsQueue.forEach((request) =>
                  request.onSuccess(token),
                );
                failedRequestsQueue = [];
              })
              .catch((err) => {
                failedRequestsQueue.forEach((request) =>
                  request.onFailure(err),
                );
                failedRequestsQueue = [];
              })
              .finally(() => {
                isRefreshing = false;
              });
          }
          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (token: string) => {
                originalConfig.headers['authorization'] = `Bearer ${token}`;
                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        }
      }
    }
    return Promise.reject(error);
  },
);

export default api;
