import React, { useCallback, useRef } from 'react';
import { FiKey } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content } from './styles';

interface NovaTabelaAtualizacaoProps {
  onClose: () => void;
  onCrud: () => void;
}

interface NovaTabelaAtualizacaoFormData {
  nome: string;
  mesInicial: number;
  anoInicial: number;
  acumuladoInicial: number;
}

const NovaTabelaAtualizacao: React.FC<NovaTabelaAtualizacaoProps> = ({
  onClose,
  onCrud,
}) => {
  const formRef = useRef<FormHandles>(null);
  const overlayRef = useRef(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: NovaTabelaAtualizacaoFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          mesInicial: Yup.number().required('Mês inicial obrigatório'),
          anoInicial: Yup.number().required('Ano inicial obrigatório'),
          acumuladoInicial: Yup.number().required(
            'Acumulado inicial obrigatório',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          nome: data.nome,
          mesInicial: data.mesInicial,
          anoInicial: data.anoInicial,
          acumuladoInicial: data.acumuladoInicial,
        };

        await api.post('/tabela', formData);
        addToast({
          type: 'success',
          title: 'Nova Tabela',
          description: 'Tabela criada com sucesso !',
        });
        onCrud();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na criação de tabela',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na criação de tabela !',
          description: 'Ocorreu um erro na criação da tabela',
        });
      }
    },
    [addToast, onCrud],
  );

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <Container ref={overlayRef} onClick={handleOverlayClick}>
      <Content>
        <header>
          <h2>Nova Tabela</h2>
          <FiX size={25} onClick={onClose} />
        </header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="nome" icon={FiKey} placeholder="Nome" autoFocus />
          <Input
            name="mesInicial"
            mask="mes"
            icon={FiKey}
            placeholder="Mês Inicial"
          />
          <Input
            name="anoInicial"
            mask="ano"
            icon={FiKey}
            placeholder="Ano Inicial"
          />
          <Input
            name="acumuladoInicial"
            icon={FiKey}
            placeholder="Acumulado Inicial"
          />

          <Button type="submit">Criar</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default NovaTabelaAtualizacao;
