import styled from 'styled-components';

export const Container = styled.div``;

export const Frame = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
`;

export const TopbarCompartilhado = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  div {
    display: flex;
    align-items: center;
    svg {
      margin-left: 20px;
    }
    h3 {
      margin-left: 10px;
    }
    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  div {
    display: flex;
    align-items: center;
    svg {
      margin-left: 20px;
    }
    h3 {
      margin-left: 10px;
    }
  }
  Button {
    margin-right: 10px;
    width: 240px;
    height: 40px;
  }
`;

export const ResumoContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
`;

export const FooterContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 8px;
`;

export const TableContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;

  div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-end;
    height: 20px;
    h3 {
      font-weight: bold;
    }
    Button {
      height: 40px;
      width: 200px;
    }
  }
`;

export const ContentLoading = styled.div`
  display: flex;
  flex: 1;
  margin-top: 80px;
  margin-left: 440px;
`;
export const Movimentacoes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
  ul {
    display: flex;
    width: 100%;
  }
  li {
    list-style: none;
  }
  div {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    h3 {
      font-size: 14px;
      color: #e63946;
    }
  }
`;

export const Content = styled.div`
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 40px;
`;

export const Solicitacoes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  ul {
    display: flex;
    width: 100%;
  }
  li {
    list-style: none;
  }
  div {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    h3 {
      font-size: 14px;
      color: #e63946;
    }
  }
`;
