import React from 'react';
import { Link } from 'react-router-dom';
import IDetalheRede from '../../models/IDetalheRede';
import { Container, Titulo, Miolo } from './styles';

const QuadroRede: React.FC<IDetalheRede> = ({
  id,
  nomeParceiro,
  avatarParceiro,
  totalContas,
  saldoGeral,
  sinalSaldoGeral,
  totalAlertas,
}) => {
  return (
    <Link to={`/dashboardContaCompartilhada/${id}`}>
      <Container>
        <Titulo sinal={sinalSaldoGeral}>
          <div>
            <img src={avatarParceiro} alt={nomeParceiro} />
          </div>
          <div>
            <h3>{nomeParceiro}</h3>
          </div>
        </Titulo>
        <Miolo>
          <div>
            <h3> Contas </h3>
            <h4>{totalContas}</h4>
          </div>
          <div>
            <h3> Saldo Geral </h3>
            <h4> {saldoGeral} </h4>
          </div>
          <div>
            <h3> Alertas </h3>
            <h4> {totalAlertas} </h4>
          </div>
        </Miolo>
      </Container>
    </Link>
  );
};

export default QuadroRede;
