import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Titulo, Miolo, Rodape } from './styles';

interface IQuadroContaIndividual {
  id: string;
  nome: string;
  creditos: string;
  debitos: string;
  correcao: string;
  saldo: string;
  sinal: string | undefined;
  dataSaldo: string;
  tabelaAtualizacao: string | null;
}

const QuadroContaIndividual: React.FC<IQuadroContaIndividual> = ({
  id,
  sinal = '+',
  nome,
  creditos,
  debitos,
  correcao,
  saldo,
  dataSaldo,
  tabelaAtualizacao,
}) => {
  return (
    <Link to={`/contaIndividual/${id}`}>
      <Container>
        <Titulo sinal={sinal}>
          <h2>{nome}</h2>
        </Titulo>
        <Miolo>
          <div>
            <h3> Créditos </h3>
            <h4>{creditos}</h4>
          </div>
          <div>
            <h3> Débitos </h3>
            <h4>{debitos}</h4>
          </div>
          {correcao !== '0,00' && (
            <div>
              <h3> Correção </h3>
              <h4>{correcao}</h4>
            </div>
          )}
        </Miolo>
        <Rodape>
          <div>
            <h3> Saldo </h3>
            <h4>{saldo}</h4>
          </div>
          <div>
            <h3> Atualizado em </h3>
            <h4>{dataSaldo}</h4>
          </div>
        </Rodape>
      </Container>
    </Link>
  );
};

export default QuadroContaIndividual;
