import React, { useState, useCallback, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  FiAlertCircle,
  FiBookOpen,
  FiClock,
  FiList,
  FiPlusCircle,
  FiUserCheck,
  FiUserX,
} from 'react-icons/fi';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import {
  Container,
  Frame,
  Topbar,
  TopbarCompartilhado,
  ResumoContainer,
  FooterContainer,
  TableContainer,
  ContentLoading,
  Movimentacoes,
  Solicitacoes,
} from './styles';
import Header from '../../../components/Header';
import QuadroResumo from '../../../components/QuadroResumo';
import Alert from '../../../components/Alert';
import api from '../../../services/api';
import IMovimento from '../../../models/IMovimento';
import Loading from '../../../components/Loading';
import ISolicitacaoMovimento from '../../../models/ISolicitacaoMovimento';
import IContaCompartilhada from '../../../models/IContaCompartilhada';

interface ContaParams {
  conta_id: string;
}

const ContaCompartilhada: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { params } = useRouteMatch<ContaParams>();
  const [isAlertSolicitacaoVisible, setIsAlertSolicitacaoVisible] = useState(
    false,
  );
  const [crudRealizado, setCrudRealizado] = useState(false);
  const [movimentacoes, setMovimentacoes] = useState<IMovimento[]>([]);
  const [conta, setConta] = useState<IContaCompartilhada>();
  const [loading, setLoading] = useState(true);
  const [loadingSolicitacao, setLoadingSolicitacao] = useState(true);
  const [movimentacoesPendentes, setMovimentacoesPendentes] = useState<
    ISolicitacaoMovimento[]
  >([]);
  const [movimentacoesRecebidas, setMovimentacoesRecebidas] = useState<
    ISolicitacaoMovimento[]
  >([]);
  const [
    solicitacaoMovimentacaoSelecionada,
    setSolicitacaoMovimentacaoSelecionada,
  ] = useState<string>();

  useEffect(() => {
    api
      .get<IContaCompartilhada>('contaCompartilhada', {
        params: {
          conta_id: params.conta_id,
        },
      })
      .then((response) => {
        setConta(response.data);
      });
  }, [params.conta_id, crudRealizado]);

  useEffect(() => {
    api
      .get<IMovimento[]>('movimentos', {
        params: {
          conta_id: params.conta_id,
        },
      })
      .then((response) => {
        setMovimentacoes(response.data);
        setLoading(false);
      });
  }, [params.conta_id, crudRealizado]);

  const listaSolicitacoes = useCallback((): void => {
    const solPendente: ISolicitacaoMovimento[] = [];
    const solRecebido: ISolicitacaoMovimento[] = [];
    api
      .get<ISolicitacaoMovimento[]>('/solicitacaoMovimento', {
        params: {
          conta_id: params.conta_id,
          situacao: 'PENDENTE',
        },
      })
      .then((response) => {
        const solicitacoes = response.data;
        solicitacoes.forEach((solicitacao) => {
          if (solicitacao.user_id === user.id) {
            solPendente.push(solicitacao);
          } else {
            solRecebido.push(solicitacao);
          }
        });
        setMovimentacoesPendentes(solPendente);
        setMovimentacoesRecebidas(solRecebido);
        setLoadingSolicitacao(false);
      })
      .catch((err) => {
        setLoadingSolicitacao(false);
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de solicitações de movimentações',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de solicitações de movimentações',
          description:
            'Ocorreu um erro na listagem de solicitações de movimentações',
        });
      });
  }, [addToast, user.id, params.conta_id]);

  useEffect(() => listaSolicitacoes(), [listaSolicitacoes, addToast]);

  const handleAceitaSolicitacao = useCallback(
    async (solicitacao_id) => {
      try {
        setLoadingSolicitacao(true);
        await api.get('/solicitacaoMovimento/aceitaSolicitacao', {
          params: {
            solicitacao_id,
          },
        });
        setLoadingSolicitacao(false);
        listaSolicitacoes();
        setCrudRealizado(true);
        addToast({
          type: 'success',
          title: 'Solicitação',
          description: 'Movimento criado com sucesso !',
        });
      } catch (err) {
        setLoadingSolicitacao(false);
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na solicitação',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação !',
          description: 'Ocorreu um erro na solicitação',
        });
      }
    },
    [addToast, listaSolicitacoes],
  );
  const handleApagaSolicitacao = useCallback(async () => {
    try {
      setIsAlertSolicitacaoVisible(false);
      setLoadingSolicitacao(true);
      await api.delete('/solicitacaoMovimento', {
        params: {
          solicitacao_id: solicitacaoMovimentacaoSelecionada,
        },
      });

      addToast({
        type: 'success',
        title: 'Exclusão de Solicitação',
        description: 'Solicitação de movimentação excluída com sucesso !',
      });
      listaSolicitacoes();
    } catch (err) {
      setLoadingSolicitacao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão de solicitação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de solcitação !',
        description: 'Ocorreu um erro na exclusão da solicitação',
      });
    }
  }, [addToast, listaSolicitacoes, solicitacaoMovimentacaoSelecionada]);

  return (
    <Container>
      <Header />
      <Frame>
        <TopbarCompartilhado>
          <div>
            <FiBookOpen size={24} />
            <h3>{conta?.nomeConta}</h3>
          </div>
          <div>
            <h3>{conta?.nomeParceiro}</h3>
            <img src={conta?.avatarParceiro} alt={conta?.nomeParceiro} />
          </div>
        </TopbarCompartilhado>
        <ResumoContainer>
          <QuadroResumo
            titulo="Meus Créditos"
            valor={conta?.totalCreditos}
            sinal="+"
          />
          <QuadroResumo
            titulo="Meus Débitos"
            valor={conta?.totalDebitos}
            sinal="-"
          />
          {conta?.totalCorrecao !== '0,00' && (
            <QuadroResumo
              titulo="Correção"
              valor={conta?.totalCorrecao}
              sinal="+"
            />
          )}
          <QuadroResumo
            titulo="Meu Saldo"
            valor={conta?.saldo}
            sinal={conta?.sinalSaldo}
          />
        </ResumoContainer>
        <FooterContainer>
          <h4>Correção: </h4>
          <h4>{conta?.nomeTabela}</h4>
        </FooterContainer>
      </Frame>
      {movimentacoesRecebidas.length > 0 && (
        <Frame>
          <Topbar>
            <div>
              <FiAlertCircle size={24} />
              <h3>Solicitações Recebidas</h3>
            </div>
          </Topbar>
          {loadingSolicitacao ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Solicitacoes>
              <ul
                style={{
                  marginTop: '5px',
                  marginBottom: '10px',
                }}
              >
                <li
                  style={{
                    width: '15%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Data
                </li>
                <li
                  style={{
                    width: '35%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Movimento
                </li>
                <li
                  style={{
                    width: '15%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Operação
                </li>
                <li
                  style={{
                    width: '15%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Valor (R$)
                </li>

                <li
                  style={{
                    width: '10%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Recusar
                </li>
                <li
                  style={{
                    width: '10%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Aceitar
                </li>
              </ul>
              {movimentacoesRecebidas.map((solicitacao) => (
                <ul key={solicitacao.id}>
                  <li style={{ width: '15%', textAlign: 'left' }}>
                    {solicitacao.dataFormatada}
                  </li>
                  <li style={{ width: '35%', textAlign: 'left' }}>
                    {solicitacao.nome}
                  </li>
                  <li style={{ width: '15%', textAlign: 'right' }}>
                    {solicitacao.operacao}
                  </li>
                  {solicitacao.operacao === 'CREDITAR' && (
                    <li
                      style={{
                        width: '15%',
                        textAlign: 'right',
                        color: '#0F1B2C',
                      }}
                    >
                      {solicitacao.valorFormatado}
                    </li>
                  )}
                  {solicitacao.operacao === 'DEBITAR' && (
                    <li
                      style={{
                        width: '15%',
                        textAlign: 'right',
                        color: '#E63946',
                      }}
                    >
                      {solicitacao.valorFormatado}
                    </li>
                  )}

                  <li
                    style={{
                      width: '10%',
                      textAlign: 'right',
                      cursor: 'pointer',
                    }}
                  >
                    <FiUserX
                      size={24}
                      title="Recusar essa solicitação de movimentação."
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsAlertSolicitacaoVisible(true);
                        setSolicitacaoMovimentacaoSelecionada(solicitacao.id);
                      }}
                    />
                  </li>

                  <li
                    style={{
                      width: '10%',
                      textAlign: 'right',
                      cursor: 'pointer',
                    }}
                  >
                    <FiUserCheck
                      size={24}
                      title="Confirmar movimentação."
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleAceitaSolicitacao(solicitacao.id);
                      }}
                    />
                  </li>
                </ul>
              ))}
            </Solicitacoes>
          )}
        </Frame>
      )}

      <Frame>
        <Topbar>
          <div>
            <FiList size={24} />
            <h3>Movimentações</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to={`/solicitaMovimentacao/${params.conta_id}`}
          >
            <FiPlusCircle
              size={26}
              title="Solicitar movimentação"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        <TableContainer>
          {loading ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Movimentacoes>
              <ul
                style={{
                  marginLeft: '20px',
                  marginTop: '5px',
                  marginBottom: '10px',
                }}
              >
                <li
                  style={{
                    width: '10%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Data
                </li>
                <li
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Movimento
                </li>
                <li
                  style={{
                    width: '25%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Lançado por
                </li>
                <li
                  style={{
                    width: '15%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Operação
                </li>
                <li
                  style={{
                    width: '10%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Valor (R$)
                </li>
              </ul>

              {!movimentacoes.length ? (
                <div>
                  <h3> Nenhuma movimentação registrada </h3>
                </div>
              ) : (
                movimentacoes.map((movimentacao) => (
                  <ul
                    key={movimentacao.id}
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    <li style={{ width: '10%', textAlign: 'left' }}>
                      {movimentacao.data}
                    </li>
                    <li style={{ width: '40%', textAlign: 'left' }}>
                      {movimentacao.nome}
                    </li>
                    <li style={{ width: '25%', textAlign: 'left' }}>
                      {movimentacao.nomeLancador}
                    </li>
                    <li style={{ width: '15%', textAlign: 'left' }}>
                      {movimentacao.operacaoMovimento}
                    </li>
                    <li
                      style={{
                        width: '10%',
                        textAlign: 'right',
                      }}
                    >
                      {movimentacao.valor}
                    </li>
                  </ul>
                ))
              )}
            </Movimentacoes>
          )}
        </TableContainer>
      </Frame>

      {movimentacoesPendentes.length > 0 && (
        <Frame>
          <Topbar>
            <div>
              <FiClock size={24} />
              <h3>Movimentações Pendentes</h3>
            </div>
          </Topbar>
          {loadingSolicitacao ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Solicitacoes>
              <ul
                style={{
                  marginTop: '5px',
                  marginBottom: '10px',
                }}
              >
                <li
                  style={{
                    width: '15%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Data
                </li>
                <li
                  style={{
                    width: '40%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Movimento
                </li>
                <li
                  style={{
                    width: '15%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Operação
                </li>
                <li
                  style={{
                    width: '15%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Valor (R$)
                </li>

                <li
                  style={{
                    width: '15%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Ações
                </li>
              </ul>
              {movimentacoesPendentes.map((solicitacao) => (
                <ul key={solicitacao.id}>
                  <li style={{ width: '15%', textAlign: 'left' }}>
                    {solicitacao.dataFormatada}
                  </li>
                  <li style={{ width: '40%', textAlign: 'left' }}>
                    {solicitacao.nome}
                  </li>
                  <li style={{ width: '15%', textAlign: 'right' }}>
                    {solicitacao.operacao}
                  </li>
                  {solicitacao.operacao === 'CREDITAR' && (
                    <li
                      style={{
                        width: '15%',
                        textAlign: 'right',
                        color: '#0F1B2C',
                      }}
                    >
                      {solicitacao.valorFormatado}
                    </li>
                  )}
                  {solicitacao.operacao === 'DEBITAR' && (
                    <li
                      style={{
                        width: '15%',
                        textAlign: 'right',
                        color: '#E63946',
                      }}
                    >
                      {solicitacao.valorFormatado}
                    </li>
                  )}

                  <li
                    style={{
                      width: '15%',
                      textAlign: 'right',
                      cursor: 'pointer',
                    }}
                  >
                    <FiUserX
                      size={24}
                      title="Eu errei ! Quero apagar esta solicitação de movimentação."
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsAlertSolicitacaoVisible(true);
                        setSolicitacaoMovimentacaoSelecionada(solicitacao.id);
                      }}
                    />
                  </li>
                </ul>
              ))}
            </Solicitacoes>
          )}
        </Frame>
      )}

      {isAlertSolicitacaoVisible ? (
        <Alert
          mensagem="Deseja realmente excluir essa solicitação de movimentação ?"
          onClose={() => setIsAlertSolicitacaoVisible(false)}
          onConfirm={handleApagaSolicitacao}
        />
      ) : null}
    </Container>
  );
};

export default ContaCompartilhada;
