import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
  background-color: #f1faee;
  border-radius: 10px;
  width: 400px;
  height: 160px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  header {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid;
    border-bottom-color: #457b9d;
    h2 {
      margin-left: 10px;
      color: #1d3557;
    }
    svg {
      color: #1d3557;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  h3 {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  div {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: space-around;
    button {
      height: 30px;
      width: 100px;
    }
  }
`;
