import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiActivity, FiEdit } from 'react-icons/fi';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import ITabelaAtualizacao from '../../../models/ITabelaAtualizacao';
import api from '../../../services/api';
import NovaTabelaAtualizacao from '../NovaTabelaAtualizacao';
import EditTabelaAtualizacao from '../EditTabelaAtualizacao';

import { Container, Content, TableContainer, Tabelas } from './styles';

const TabelasAtualizacao: React.FC = () => {
  const [tabelas, setTabelas] = useState<ITabelaAtualizacao[]>([]);
  const [isNovaTabelaVisible, setIsNovaTabelaVisible] = useState(false);
  const [isEditTabelaVisible, setIsEditTabelaVisible] = useState(false);
  const [crudRealizado, setCrudRealizado] = useState(false);
  const [tabelaSelecionada, setTabelaSelecionada] = useState('');

  useEffect(() => {
    api.get<ITabelaAtualizacao[]>('tabelas').then((response) => {
      setTabelas(response.data);
    });
  }, [crudRealizado]);

  const handleNovaTabela = useCallback(() => {
    setIsNovaTabelaVisible(!isNovaTabelaVisible);
  }, [isNovaTabelaVisible]);

  const handleEditTabela = useCallback(
    (id) => {
      setIsEditTabelaVisible(!isEditTabelaVisible);
      setTabelaSelecionada(id);
    },
    [isEditTabelaVisible],
  );

  return (
    <Container>
      <Header />
      <Content>
        <TableContainer>
          <div>
            <h3>Tabelas de Atualização</h3>
            <Button type="button" onClick={handleNovaTabela}>
              Nova Tabela
            </Button>
          </div>

          <Tabelas>
            <ul
              style={{
                marginLeft: '20px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <li
                style={{ width: '60%', textAlign: 'left', fontWeight: 'bold' }}
              >
                Tabela
              </li>
              <li
                style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}
              >
                Índices
              </li>
              <li
                style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}
              >
                Editar
              </li>
            </ul>

            {tabelas.map((tabela) => (
              <ul
                key={tabela.id}
                style={{
                  marginLeft: '20px',
                }}
              >
                <li style={{ width: '60%', textAlign: 'left' }}>
                  {tabela.nome}
                </li>

                <li
                  style={{
                    width: '20%',
                    textAlign: 'right',
                    cursor: 'pointer',
                  }}
                >
                  <Link to={`/indice/${tabela.id}`}>
                    <FiActivity size={20} />
                  </Link>
                </li>

                <li
                  style={{
                    width: '20%',
                    textAlign: 'right',
                    cursor: 'pointer',
                  }}
                >
                  <FiEdit
                    size={20}
                    onClick={() => handleEditTabela(tabela.id)}
                  />
                </li>
              </ul>
            ))}
          </Tabelas>
        </TableContainer>
        {isNovaTabelaVisible ? (
          <NovaTabelaAtualizacao
            onClose={() => setIsNovaTabelaVisible(false)}
            onCrud={() => setCrudRealizado(!crudRealizado)}
          />
        ) : null}
        {isEditTabelaVisible ? (
          <EditTabelaAtualizacao
            tabela_id={tabelaSelecionada}
            onClose={() => setIsEditTabelaVisible(false)}
            onCrud={() => setCrudRealizado(!crudRealizado)}
          />
        ) : null}
      </Content>
    </Container>
  );
};

export default TabelasAtualizacao;
