import styled from 'styled-components';

export const Container = styled.div``;

export const Frame = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  background: #fff;
`;

export const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  div {
    display: flex;
    align-items: center;
    svg {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  Button {
    margin-right: 10px;
    height: 40px;
  }
`;

export const ContentLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  form {
    display: flex;
    flex-direction: column;
    flex: 1;
    div {
      h4 {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    Button {
      margin-top: 20px;
    }
  }
`;
