import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${(props) =>
    props.isErrored &&
    css`
      background: #e63946;
    `}
`;
