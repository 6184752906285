/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';

interface Option {
  id: string | number;
  label: string;
}

interface Props {
  name: string;
  options: Option[];
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const Radio: React.FC<InputProps> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, error, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref: { checked: any }) => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find(
          (ref: { value: unknown }) => ref.value === value,
        );

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      {options.map((option, index) => (
        <label key={option.id}>
          <input
            ref={(ref) => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={defaultValue === option.id}
            {...rest}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </Container>
  );
};

export default Radio;
