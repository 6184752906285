import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    font-size: 18px;
    span {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  ${(props) =>
    props.isErrored &&
    css`
      background: #e63946;
    `}
`;
