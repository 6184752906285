import React, { useState, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import {
  Container,
  CabecalhoIndice,
  Content,
  TableContainer,
  Indices,
} from './styles';
import Header from '../../../components/Header';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import NovoIndice from '../NovoIndice';
import api from '../../../services/api';
import ITabelaAtualizacao from '../../../models/ITabelaAtualizacao';
import IIndiceMensal from '../../../models/IIndiceMensal';
import ButtonRed from '../../../components/ButtonRed';

interface IndiceParams {
  tabela_id: string;
}

const Indice: React.FC = () => {
  const { addToast } = useToast();
  const { params } = useRouteMatch<IndiceParams>();
  const [isNovoIndiceVisible, setIsNovoIndiceVisible] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [crudRealizado, setCrudRealizado] = useState(false);
  const [indices, setIndices] = useState<IIndiceMensal[]>([]);
  const [tabela, setTabela] = useState<ITabelaAtualizacao>();

  useEffect(() => {
    api
      .get<ITabelaAtualizacao>('tabela', {
        params: {
          tabela_id: params.tabela_id,
        },
      })
      .then((response) => {
        setTabela(response.data);
      });
  }, [params.tabela_id]);

  useEffect(() => {
    api
      .get<IIndiceMensal[]>('indiceMensal', {
        params: {
          tabela_id: params.tabela_id,
        },
      })
      .then((response) => {
        setIndices(response.data);
      });
  }, [params.tabela_id, crudRealizado]);

  const handleNovoIndice = useCallback(() => {
    setIsNovoIndiceVisible(!isNovoIndiceVisible);
  }, [isNovoIndiceVisible]);

  const handleExcluiIndice = useCallback(async () => {
    try {
      await api.delete('/indiceMensal', {
        params: {
          tabela_id: params.tabela_id,
        },
      });

      addToast({
        type: 'success',
        title: 'Exclusão de Índice',
        description: 'Índice excluído com sucesso !',
      });

      setIsAlertVisible(false);
      setCrudRealizado(!crudRealizado);
    } catch (err) {
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão de índice',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de índice !',
        description: 'Ocorreu um erro na exclusão de índice',
      });
    }
  }, [addToast, params.tabela_id, crudRealizado]);

  return (
    <Container>
      <Header />
      <CabecalhoIndice>
        <h2>{tabela?.nome}</h2>
      </CabecalhoIndice>
      <Content>
        <TableContainer>
          <div>
            <Button type="button" onClick={handleNovoIndice}>
              Novo Índice
            </Button>
            <ButtonRed type="button" onClick={() => setIsAlertVisible(true)}>
              Exclur Índice
            </ButtonRed>
          </div>

          <Indices>
            <tr>
              <th>Mês</th>
              <th>Ano</th>
              <th>Índice</th>
              <th>Acumulado</th>
            </tr>

            {indices.map((indice) => (
              <tr key={indice.id}>
                <td>{indice.mes}</td>
                <td>{indice.ano}</td>
                <td>{indice.indice}</td>
                <td>{indice.acumulado}</td>
              </tr>
            ))}
          </Indices>
        </TableContainer>
      </Content>
      {isNovoIndiceVisible ? (
        <NovoIndice
          tabela_id={params.tabela_id}
          nome_tabela={tabela?.nome}
          onClose={() => setIsNovoIndiceVisible(false)}
          onCrud={() => setCrudRealizado(!crudRealizado)}
        />
      ) : null}
      {isAlertVisible ? (
        <Alert
          mensagem="Deseja realmente excluir índice ?"
          onClose={() => setIsAlertVisible(false)}
          onConfirm={handleExcluiIndice}
        />
      ) : null}
    </Container>
  );
};

export default Indice;
