import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useToast } from '../../../hooks/toast';
import Loading from '../../../components/Loading';

import { Container, Content } from './styles';
import api from '../../../services/api';

const HabilitaAcesso: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const habilitaAcesso = useCallback((): void => {
    const token = location.search.replace('?token=', '');
    api
      .post('/users/habilita-acesso', {
        token,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Habilitação realizada com sucesso !',
          description: 'Favor realizar o login',
        });
        history.push('/');
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro ao habilitar acesso',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao habilitar acesso',
          description: 'Ocorreu um erro ao habilitar seu acesso',
        });
      });
  }, [location.search, addToast, history]);

  useEffect(() => habilitaAcesso(), [habilitaAcesso]);

  return (
    <Container>
      <Content>{loading ? <Loading /> : <h1>Promise</h1>}</Content>
    </Container>
  );
};

export default HabilitaAcesso;
