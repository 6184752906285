/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState, useEffect } from 'react';
import {
  FiAlertCircle,
  FiClock,
  FiPlusCircle,
  FiUserCheck,
  FiUsers,
  FiUserX,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import QuadroRede from '../../../components/QuadroRede';
import {
  Container,
  Frame,
  Topbar,
  Content,
  ContentLoading,
  RedeContainer,
} from './styles';
import Header from '../../../components/Header';
import Alert from '../../../components/Alert';
import api from '../../../services/api';
import Loading from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { TableContainer } from '../../../components/Table';
import ISolicitacao from '../../../models/ISolicitacaoRede';
import IDetalheRede from '../../../models/IDetalheRede';

const DashboardRede: React.FC = () => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAlertRejeitaVisible, setIsAlertRejeitaVisible] = useState(false);
  const [redes, setRedes] = useState<IDetalheRede[]>([]);
  const [solicitacoesPendentes, setSolicitacoesPendentes] = useState<
    ISolicitacao[]
  >([]);
  const [solicitacoesRecebidas, setSolicitacoesRecebidas] = useState<
    ISolicitacao[]
  >([]);
  const [solicitacaoSelecionada, setSolicitacaoSelecionada] = useState<
    string
  >();
  const [loading, setLoading] = useState(true);
  const [loadingSolicitacao, setLoadingSolicitacao] = useState(true);
  const { addToast } = useToast();
  const { signOut, user } = useAuth();

  const listaRede = useCallback((): void => {
    api
      .get<IDetalheRede[]>('/rede', {})
      .then((response) => {
        setRedes(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de rede',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de rede',
          description: 'Ocorreu um erro na listagem de rede',
        });
      });
  }, [addToast, signOut]);

  useEffect(() => listaRede(), [listaRede, addToast]);

  const listaSolicitacoes = useCallback((): void => {
    const solPendente: ISolicitacao[] = [];
    const solRecebido: ISolicitacao[] = [];
    api
      .get<ISolicitacao[]>('/solicitacaoRede', {
        params: {
          situacao: 'PENDENTE',
        },
      })
      .then((response) => {
        const solicitacoes = response.data;
        solicitacoes.forEach((solicitacao) => {
          if (solicitacao.user_id === user.id) {
            solPendente.push(solicitacao);
          } else {
            solRecebido.push(solicitacao);
          }
        });
        setSolicitacoesPendentes(solPendente);
        setSolicitacoesRecebidas(solRecebido);
        setLoadingSolicitacao(false);
      })
      .catch((err) => {
        setLoadingSolicitacao(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de solicitações pendentes',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de solicitações pendentes',
          description: 'Ocorreu um erro na listagem de solicitações pendentes',
        });
      });
  }, [addToast, signOut, user.id]);

  useEffect(() => listaSolicitacoes(), [listaSolicitacoes, addToast]);

  const handleDeleteSolicitacao = useCallback(async () => {
    try {
      setIsAlertVisible(false);
      setLoadingSolicitacao(true);
      await api.delete('/solicitacaoRede', {
        params: {
          solicitacao_id: solicitacaoSelecionada,
        },
      });
      setLoadingSolicitacao(false);
      listaSolicitacoes();
      addToast({
        type: 'success',
        title: 'Solicitação',
        description: 'Solicitação excluída com sucesso !',
      });
    } catch (err) {
      setLoadingSolicitacao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão de solicitação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de solicitação !',
        description: 'Ocorreu um erro na exclusão da solicitação',
      });
    }
  }, [addToast, listaSolicitacoes, solicitacaoSelecionada]);

  const handleAceitaSolicitacao = useCallback(
    async (solicitacao_id) => {
      try {
        setLoadingSolicitacao(true);
        await api.get('/solicitacaoRede/aceitaSolicitacao', {
          params: {
            solicitacao_id,
          },
        });
        setLoadingSolicitacao(false);
        listaSolicitacoes();
        listaRede();
        addToast({
          type: 'success',
          title: 'Solicitação',
          description: 'Rede criada com sucesso !',
        });
      } catch (err) {
        setLoadingSolicitacao(false);
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na solicitação',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na solicitação !',
          description: 'Ocorreu um erro na solicitação',
        });
      }
    },
    [addToast, listaSolicitacoes, listaRede],
  );

  const handleRejeitaSolicitacao = useCallback(async () => {
    try {
      setIsAlertRejeitaVisible(false);
      setLoadingSolicitacao(true);
      await api.get('/solicitacaoRede/rejeitaSolicitacao', {
        params: {
          solicitacao_id: solicitacaoSelecionada,
        },
      });
      setLoadingSolicitacao(false);
      listaSolicitacoes();
      addToast({
        type: 'success',
        title: 'Solicitação',
        description: 'Solicitação rejeitada com sucesso !',
      });
    } catch (err) {
      setLoadingSolicitacao(false);
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na solicitação',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na solicitação !',
        description: 'Ocorreu um erro na solicitação',
      });
    }
  }, [addToast, listaSolicitacoes, solicitacaoSelecionada]);

  return (
    <Container>
      <Header />
      {solicitacoesRecebidas.length > 0 && (
        <Frame>
          <Topbar>
            <div>
              <FiAlertCircle size={24} />
              <h3>Solicitações Recebidas</h3>
            </div>
          </Topbar>
          {loadingSolicitacao ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Content>
              <TableContainer>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Solicitante</th>
                    <th>Convite</th>
                    <th>Recusar</th>
                    <th>Aceitar</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitacoesRecebidas.map((solicitacao) => (
                    <tr key={solicitacao.id}>
                      <td>{solicitacao.dataConvite}</td>
                      <td>
                        <div>
                          <img
                            style={{ width: '50px', height: '50px' }}
                            src={solicitacao.user.avatar_url}
                            alt={solicitacao.user.name}
                          />
                          {solicitacao.user.name}
                        </div>
                      </td>
                      <td>{solicitacao.textoConvite}</td>

                      <td>
                        <FiUserX
                          size={24}
                          title="Não, obrigado. Quero recusar este convite."
                          style={{ cursor: 'pointer', marginRight: '20px' }}
                          onClick={() => {
                            setIsAlertRejeitaVisible(true);
                            setSolicitacaoSelecionada(solicitacao.id);
                          }}
                        />
                      </td>
                      <td>
                        <FiUserCheck
                          size={24}
                          title="Aceitar este convite e criar a Conta!"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleAceitaSolicitacao(solicitacao.id)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            </Content>
          )}
        </Frame>
      )}
      <Frame>
        <Topbar>
          <div>
            <FiUsers size={24} />
            <h3>Minha Rede</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to="/novoRelacionamento"
          >
            <FiPlusCircle
              size={26}
              title="Novo Convite"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        {loading ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            {!redes.length ? (
              <h2>Você não possui nenhum relacionamento ! </h2>
            ) : (
              <RedeContainer>
                {redes.map((rede) => (
                  <QuadroRede
                    key={rede.id}
                    id={rede.id}
                    nomeParceiro={rede.nomeParceiro}
                    avatarParceiro={rede.avatarParceiro}
                    saldoGeral={rede.saldoGeral}
                    sinalSaldoGeral={rede.sinalSaldoGeral}
                    totalContas={rede.totalContas}
                    totalAlertas={rede.totalAlertas}
                  />
                ))}
              </RedeContainer>
            )}
          </Content>
        )}
      </Frame>

      {solicitacoesPendentes.length > 0 && (
        <Frame>
          <Topbar>
            <div>
              <FiClock size={24} />
              <h3>Convites Pendentes</h3>
            </div>
          </Topbar>
          {loadingSolicitacao ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Content>
              <TableContainer>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Email do Convidado</th>
                    <th>Convite</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitacoesPendentes.map((solicitacao) => (
                    <tr key={solicitacao.id}>
                      <td>{solicitacao.dataConvite}</td>
                      <td>{solicitacao.emailConvidado}</td>
                      <td>{solicitacao.textoConvite}</td>
                      <td>
                        <FiUserX
                          size={24}
                          title="Eu desisti ! Quero cancelar este convite."
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsAlertVisible(true);
                            setSolicitacaoSelecionada(solicitacao.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            </Content>
          )}
        </Frame>
      )}
      {isAlertVisible ? (
        <Alert
          mensagem="Deseja realmente apagar esse convite ?"
          onClose={() => setIsAlertVisible(false)}
          onConfirm={handleDeleteSolicitacao}
        />
      ) : null}
      {isAlertRejeitaVisible ? (
        <Alert
          mensagem="Deseja realmente recusar esse convite ?"
          onClose={() => setIsAlertRejeitaVisible(false)}
          onConfirm={handleRejeitaSolicitacao}
        />
      ) : null}
    </Container>
  );
};

export default DashboardRede;
