import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  &::after {
    content: ' ';
    display: block;
    width: 30px;
    height: 30px;
    margin: 4px;
    border-radius: 50%;
    border: 6px solid #f1faee;
    border-color: #f1faee transparent #f1faee transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
