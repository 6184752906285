import React, { useCallback, useRef, useState } from 'react';
import { FiArrowLeft, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import AlertOk from '../../../components/AlertOk';

import getValidationErrors from '../../../utils/getValidationErrors';

import logoImg from '../../../assets/logo.png';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content, ContentLoading } from './styles';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().min(
            6,
            'A senha deve ter no minimo 6 caracteres',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);
        setLoading(false);
        setIsAlertVisible(true);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao realizar o cadastro, tente novamente',
        });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Promise" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Faça o seu cadastro</h1>
          <Input name="name" icon={FiUser} placeholder="Nome" />
          <Input name="email" icon={FiMail} placeholder="E-mail" />
          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Senha"
          />
          {loading ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Button type="submit" style={{ marginTop: '10px' }}>
              Cadastrar
            </Button>
          )}
        </Form>
        <Link to="/">
          <FiArrowLeft />
          Voltar para logon
        </Link>
      </Content>
      {isAlertVisible ? (
        <AlertOk
          mensagem={`Para ativação de seu cadastro, clique no link de confirmação que foi enviado para o e-mail ${formRef.current?.getFieldValue(
            'email',
          )}.`}
          onClose={() => {
            setIsAlertVisible(false);
            history.push('/');
          }}
        />
      ) : null}
    </Container>
  );
};

export default SignUp;
