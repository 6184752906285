import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FiBookOpen, FiEdit, FiList, FiPlusCircle } from 'react-icons/fi';

import {
  Container,
  Frame,
  Topbar,
  ResumoContainer,
  FooterContainer,
  TableContainer,
  ContentLoading,
  Movimentacoes,
} from './styles';
import Header from '../../../components/Header';
import QuadroResumo from '../../../components/QuadroResumo';
import api from '../../../services/api';
import IContaIndividual from '../../../models/IContaIndividual';
import IMovimento from '../../../models/IMovimento';
import Loading from '../../../components/Loading';
import { enumOperacaoMovimento } from '../../../models/IOperacaoMovimento';

interface ContaParams {
  conta_id: string;
}

const ContaIndividual: React.FC = () => {
  const { params } = useRouteMatch<ContaParams>();
  const [movimentacoes, setMovimentacoes] = useState<IMovimento[]>([]);
  const [conta, setConta] = useState<IContaIndividual>(Object);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get<IContaIndividual>('contaIndividual', {
        params: {
          conta_id: params.conta_id,
        },
      })
      .then((response) => {
        setConta(response.data);
      });
  }, [params.conta_id]);

  useEffect(() => {
    api
      .get<IMovimento[]>('movimentos', {
        params: {
          conta_id: params.conta_id,
        },
      })
      .then((response) => {
        setMovimentacoes(response.data);
        setLoading(false);
      });
  }, [params.conta_id]);

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiBookOpen size={24} />
            <h3>{conta.nomeConta}</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to={`/editContaIndividual/${conta.id}`}
          >
            <FiEdit
              size={24}
              title="Alterar conta"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        <ResumoContainer>
          <QuadroResumo
            titulo="Créditos"
            valor={conta?.totalCreditos}
            sinal="+"
          />
          <QuadroResumo
            titulo="Débitos"
            valor={conta?.totalDebitos}
            sinal="-"
          />
          {conta?.totalCorrecao !== '0,00' && (
            <QuadroResumo
              titulo="Correção"
              valor={conta.totalCorrecao}
              sinal="+"
            />
          )}
          <QuadroResumo
            titulo="Saldo"
            valor={conta.saldo}
            sinal={conta.sinalSaldo}
          />
          <QuadroResumo
            titulo="Atualizado em"
            valor={conta.dataSaldo}
            sinal={conta.sinalSaldo}
          />
        </ResumoContainer>
        <FooterContainer>
          <h4>Correção: </h4>
          <h4>{conta.nomeTabela}</h4>
        </FooterContainer>
      </Frame>
      <Frame>
        <Topbar>
          <div>
            <FiList size={24} />
            <h3>Movimentações</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to={`/novaMovimentacao/${conta.id}`}
          >
            <FiPlusCircle
              size={26}
              title="Inserir nova movimentação"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        <TableContainer>
          {loading ? (
            <ContentLoading>
              <Loading />
            </ContentLoading>
          ) : (
            <Movimentacoes>
              <ul
                style={{
                  marginLeft: '20px',
                  marginTop: '5px',
                  marginBottom: '10px',
                }}
              >
                <li
                  style={{
                    width: '10%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Data
                </li>
                <li
                  style={{
                    width: '55%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Movimento
                </li>
                <li
                  style={{
                    width: '10%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  Operação
                </li>
                <li
                  style={{
                    width: '10%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Valor
                </li>
                <li
                  style={{
                    width: '15%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  Editar
                </li>
              </ul>

              {!movimentacoes.length ? (
                <div>
                  <h3> Nenhuma movimentação registrada </h3>
                </div>
              ) : (
                movimentacoes.map((movimentacao) => (
                  <ul
                    key={movimentacao.id}
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    <li style={{ width: '10%', textAlign: 'left' }}>
                      {movimentacao.data}
                    </li>
                    <li style={{ width: '55%', textAlign: 'left' }}>
                      {movimentacao.nome}
                    </li>
                    <li style={{ width: '10%', textAlign: 'left' }}>
                      {movimentacao.operacaoMovimento}
                    </li>
                    {movimentacao.operacaoMovimento ===
                      enumOperacaoMovimento.DEBITADO && (
                      <li
                        style={{
                          width: '10%',
                          textAlign: 'right',
                          color: '#E63946',
                        }}
                      >
                        {movimentacao.valor}
                      </li>
                    )}
                    {movimentacao.operacaoMovimento ===
                      enumOperacaoMovimento.CREDITADO && (
                      <li
                        style={{
                          width: '10%',
                          textAlign: 'right',
                          color: '#0F1B2C',
                        }}
                      >
                        {movimentacao.valor}
                      </li>
                    )}
                    <li
                      style={{
                        width: '15%',
                        textAlign: 'right',
                        cursor: 'pointer',
                      }}
                    >
                      <Link
                        style={{ textDecoration: 'none', color: '#1d3557' }}
                        to={`/editMovimentacao/${conta.id}/${movimentacao.id}`}
                      >
                        <FiEdit
                          size={20}
                          title="Alterar movimentação"
                          style={{ cursor: 'pointer' }}
                        />
                      </Link>
                    </li>
                  </ul>
                ))
              )}
            </Movimentacoes>
          )}
        </TableContainer>
      </Frame>
    </Container>
  );
};

export default ContaIndividual;
