import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Titulo, Miolo, Rodape } from './styles';

interface IQuadroContaCompartilhada {
  id: string;
  nome: string;
  creditos: string;
  debitos: string;
  correcao: string;
  saldo: string;
  sinal: string | undefined;
  dataSaldo: string;
  avatar_url: string;
  convidado: string;
}

const QuadroContaCompartilhada: React.FC<IQuadroContaCompartilhada> = ({
  id,
  sinal = '+',
  nome,
  creditos,
  debitos,
  correcao,
  saldo,
  dataSaldo,
  avatar_url,
  convidado,
}) => {
  return (
    <Link to={`/contaCompartilhada/${id}`}>
      <Container>
        <Titulo sinal={sinal}>
          <div>
            <img src={avatar_url} alt={convidado} />
            <h4>{convidado}</h4>
          </div>
          <div>
            <h3>{nome}</h3>
          </div>
        </Titulo>
        <Miolo>
          <div>
            <h3> Créditos </h3>
            <h4>{creditos}</h4>
          </div>
          <div>
            <h3> Débitos </h3>
            <h4>{debitos}</h4>
          </div>
          {correcao !== '0,00' && (
            <div>
              <h3> Correção </h3>
              <h4>{correcao}</h4>
            </div>
          )}
        </Miolo>
        <Rodape>
          <div>
            <h3> Saldo </h3>
            <h4>{saldo}</h4>
          </div>
          <div>
            <h3> Atualizado até </h3>
            <h4>{dataSaldo}</h4>
          </div>
        </Rodape>
      </Container>
    </Link>
  );
};

export default QuadroContaCompartilhada;
