import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiKey } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { Container, Content } from './styles';
import IIndiceMensal from '../../../models/IIndiceMensal';

interface NovoIndiceProps {
  tabela_id: string;
  nome_tabela: string | undefined;
  onClose: () => void;
  onCrud: () => void;
}

interface NovoIndiceFormData {
  tabela_id: string;
  mes: number;
  ano: number;
  indice: number;
}

const NovoIndice: React.FC<NovoIndiceProps> = ({
  tabela_id,
  nome_tabela,
  onClose,
  onCrud,
}) => {
  const [ultimoIndice, setUltimoIndice] = useState<IIndiceMensal>();
  const formRef = useRef<FormHandles>(null);
  const overlayRef = useRef(null);

  const { addToast } = useToast();

  useEffect(() => {
    api
      .get<IIndiceMensal>('indiceMensal/ultimoIndice', {
        params: {
          tabela_id,
        },
      })
      .then((response) => {
        setUltimoIndice(response.data);
      });
  }, [tabela_id]);

  const handleSubmit = useCallback(
    async (data: NovoIndiceFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          indice: Yup.number().required('Índice obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          tabela_id,
          indice: data.indice,
        };

        await api.post('/indiceMensal', formData);
        setUltimoIndice(undefined);
        addToast({
          type: 'success',
          title: 'Novo Índice',
          description: 'Índice criado com sucesso !',
        });
        onCrud();
        formRef.current?.clearField('indice');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na criação de índice',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na criação de índice !',
          description: 'Ocorreu um erro na criação do índice',
        });
      }
    },
    [addToast, onCrud, tabela_id],
  );

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <Container ref={overlayRef} onClick={handleOverlayClick}>
      <Content>
        <header>
          <h2>{nome_tabela}</h2>
          <FiX size={25} onClick={onClose} />
        </header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h3>
            Último Índice: {ultimoIndice?.mes} / {ultimoIndice?.ano}
          </h3>
          <Input
            name="indice"
            icon={FiKey}
            placeholder="Próximo Índice"
            autoFocus
          />

          <Button type="submit">Inserir</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default NovoIndice;
