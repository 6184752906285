import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  margin-left: 40px;
  margin-top: 40px;
`;

export const TableContainer = styled.section`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 20px;

  div {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-end;
    height: 20px;

    h3 {
      font-weight: bold;
    }
    Button {
      height: 40px;
      width: 200px;
    }
  }
`;

export const Tabelas = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  max-width: 400px;
  ul {
    display: flex;
    width: 100%;
  }
  li {
    list-style: none;
  }
`;
