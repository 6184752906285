import React, { useCallback, useRef, useState } from 'react';
import {
  FiKey,
  FiCalendar,
  FiDollarSign,
  FiEdit2,
  FiXSquare,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { useHistory, useRouteMatch } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Radio from '../../../components/Radio';

import { Container, Content, Frame, Topbar } from './styles';
import currencyToNumber from '../../../utils/currencyToNumber';
import dateToDate from '../../../utils/dateToDate';
import { enumTipoCRUD } from '../../../models/TipoCRUD';
import Header from '../../../components/Header';

interface NovaMovimentacaoParams {
  conta_id: string;
}

interface NovaMovimentacaoFormData {
  nome: string;
  data: string;
  valor: string;
  tipo: string;
}

const NovaMovimentacao: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<NovaMovimentacaoParams>();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: NovaMovimentacaoFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          data: Yup.string().required('Data obrigatório'),
          valor: Yup.string().required('Valor obrigatório'),
          tipo: Yup.string().required('Favor informar a operação'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          tipoCRUD: enumTipoCRUD.INSERIR,
          movimento_id: '0',
          nome: data.nome,
          data: dateToDate(data.data),
          valor: currencyToNumber(data.valor),
          operacaoMovimento: data.tipo,
          conta_id: params.conta_id,
        };

        if (!loading) {
          await api.post('/movimento', formData);
        }

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Nova Movimentação',
          description: 'Movimentação adicionada com sucesso !',
        });
        history.goBack();
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na criação de movimentação',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na criação de movimentação !',
          description: 'Ocorreu um erro na criação da movimentação',
        });
      }
    },
    [addToast, params.conta_id, history, loading],
  );

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiEdit2 size={24} />
            <h3>Nova Movimentação</h3>
          </div>
          <div>
            <FiXSquare
              size={24}
              title="Fechar"
              cursor="pointer"
              onClick={() => history.goBack()}
            />
          </div>
        </Topbar>

        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <h4>Data</h4>
              <Input
                name="data"
                icon={FiCalendar}
                mask="date"
                defaultValue={format(new Date(), 'dd/MM/yyyy')}
              />
            </div>
            <div>
              <h4>Nome</h4>
              <Input name="nome" icon={FiKey} defaultValue="Lançamento" />
            </div>
            <div>
              <h4>Valor</h4>
              <Input
                name="valor"
                icon={FiDollarSign}
                mask="currency"
                autoFocus
              />
            </div>
            <div>
              <h4>Operação</h4>
              <Radio
                name="tipo"
                options={[
                  { id: 'CREDITADO', label: 'Creditar' },
                  { id: 'DEBITADO', label: 'Debitar' },
                ]}
              />
            </div>
            <Button type="submit" loading={loading}>
              Inserir Movimentação
            </Button>
          </Form>
        </Content>
      </Frame>
    </Container>
  );
};

export default NovaMovimentacao;
