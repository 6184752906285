import styled from 'styled-components';

interface ContainerProps {
  sinal: string;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 225px;
  height: 225px;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-right: 15px;
  color: #1d3557;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.5);
`;

export const Titulo = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: ${({ sinal }: ContainerProps): string =>
    sinal === '-' ? '2px solid #e63946' : '2px solid #457b9d'};
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  h3 {
    text-align: left;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const Miolo = styled.div`
  display: flex;
  flex: 1;
  align-itens: center;
  justify-content: center;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      text-align: left;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 16px;
    }
    h4 {
      text-align: right;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
`;
