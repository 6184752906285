import React, { useCallback, useState, useEffect } from 'react';
import { FiPlusCircle, FiUser, FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import QuadroConta from '../../../components/QuadroContaIndividual';
import {
  Container,
  Frame,
  Topbar,
  Content,
  ContentLoading,
  ContaContainer,
} from './styles';
import Header from '../../../components/Header';
import api from '../../../services/api';
import Loading from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import IContaIndividual from '../../../models/IContaIndividual';
import IContaCompartilhada from '../../../models/IContaCompartilhada';
import QuadroContaCompartilhada from '../../../components/QuadroContaCompartilhada';

const DashboardContas: React.FC = () => {
  const [contasIndividuais, setContasIndividuais] = useState<
    IContaIndividual[]
  >([]);
  const [contasCompartilhadas, setContasCompartilhadas] = useState<
    IContaCompartilhada[]
  >([]);
  const [loadingContasIndividuais, setLoadingContasIndividuais] = useState(
    true,
  );
  const [
    loadingContasCompartilhadas,
    setLoadingContasCompartilhadas,
  ] = useState(true);
  const { addToast } = useToast();
  const { signOut } = useAuth();

  const listaContasIndividuais = useCallback((): void => {
    api
      .get<IContaIndividual[]>('/contas', {
        params: {
          tipoConta: 'INDIVIDUAL',
        },
      })
      .then((response) => {
        setContasIndividuais(response.data);
        setLoadingContasIndividuais(false);
      })
      .catch((err) => {
        setLoadingContasIndividuais(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de contas',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de contas',
          description: 'Ocorreu um erro na listagem de contas',
        });
      });
  }, [addToast, signOut]);

  useEffect(() => listaContasIndividuais(), [listaContasIndividuais, addToast]);

  const listaContasCompartilhadas = useCallback((): void => {
    api
      .get<IContaCompartilhada[]>('/contas', {
        params: {
          tipoConta: 'COMPARTILHADA',
        },
      })
      .then((response) => {
        setContasCompartilhadas(response.data);
        setLoadingContasCompartilhadas(false);
      })
      .catch((err) => {
        setLoadingContasCompartilhadas(false);
        signOut();
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na listagem de contas',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na listagem de contas',
          description: 'Ocorreu um erro na listagem de contas',
        });
      });
  }, [addToast, signOut]);

  useEffect(() => listaContasCompartilhadas(), [
    listaContasCompartilhadas,
    addToast,
  ]);

  return (
    <Container>
      <Header />
      <Frame>
        <Topbar>
          <div>
            <FiUser size={24} />
            <h3>Contas Individuais</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to="/novaConta"
          >
            <FiPlusCircle
              size={26}
              title="Criar nova conta"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        {loadingContasIndividuais ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            {!contasIndividuais.length ? (
              <h2>Você não possui nenhuma conta individual </h2>
            ) : (
              <ContaContainer>
                {contasIndividuais.map((conta) => (
                  <QuadroConta
                    key={conta.id}
                    id={conta.id}
                    nome={conta.nomeConta}
                    creditos={conta.totalCreditos}
                    debitos={conta.totalDebitos}
                    correcao={conta.totalCorrecao}
                    saldo={conta.saldo}
                    sinal={conta.sinalSaldo}
                    dataSaldo={conta.dataSaldo}
                    tabelaAtualizacao={conta.nomeTabela}
                  />
                ))}
              </ContaContainer>
            )}
          </Content>
        )}
      </Frame>
      <Frame>
        <Topbar>
          <div>
            <FiUsers size={24} />
            <h3>Contas Compartilhadas</h3>
          </div>
          <Link
            style={{ textDecoration: 'none', color: '#1d3557' }}
            to="/novaContaCompartilhada"
          >
            <FiPlusCircle
              size={26}
              title="Criar nova conta compartilhada"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          </Link>
        </Topbar>
        {loadingContasCompartilhadas ? (
          <ContentLoading>
            <Loading />
          </ContentLoading>
        ) : (
          <Content>
            {!contasCompartilhadas.length ? (
              <h2>Você não possui nenhuma conta compartilhada </h2>
            ) : (
              <ContaContainer>
                {contasCompartilhadas.map((conta) => (
                  <QuadroContaCompartilhada
                    key={conta.id}
                    id={conta.id}
                    nome={conta.nomeConta}
                    creditos={conta.totalCreditos}
                    debitos={conta.totalDebitos}
                    correcao={conta.totalCorrecao}
                    saldo={conta.saldo}
                    sinal={conta.sinalSaldo}
                    dataSaldo={conta.dataSaldo}
                    avatar_url={conta.avatarParceiro}
                    convidado={conta.nomeParceiro}
                  />
                ))}
              </ContaContainer>
            )}
          </Content>
        )}
      </Frame>
    </Container>
  );
};

export default DashboardContas;
