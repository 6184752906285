import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiKey } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ButtonRed from '../../../components/ButtonRed';

import { Container, Content } from './styles';

interface EditTabelaAtualizacaoProps {
  tabela_id: string;
  onClose: () => void;
  onCrud: () => void;
}

interface EditTabelaAtualizacaoFormData {
  nome: string;
}

const EditTabelaAtualizacao: React.FC<EditTabelaAtualizacaoProps> = ({
  tabela_id,
  onClose,
  onCrud,
}) => {
  const formRef = useRef<FormHandles>(null);
  const overlayRef = useRef(null);

  const { addToast } = useToast();
  const [tabela, setTabela] = useState<EditTabelaAtualizacaoFormData>();

  useEffect(() => {
    api
      .get<EditTabelaAtualizacaoFormData>('tabela', {
        params: {
          tabela_id,
        },
      })
      .then((response) => {
        setTabela(response.data);
      });
  }, [tabela_id]);

  const handleDelete = useCallback(async () => {
    try {
      await api.delete('/tabela', {
        params: {
          tabela_id,
        },
      });

      addToast({
        type: 'success',
        title: 'Exclusão de Tabela',
        description: 'Tabela excluída com sucesso !',
      });
      onCrud();
      onClose();
    } catch (err) {
      if (err.response) {
        addToast({
          type: 'error',
          title: 'Erro na exclusão de tabela',
          description: err.response.data.message,
        });
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro na exclusão de tabela !',
        description: 'Ocorreu um erro na exclusão da tabela',
      });
    }
  }, [addToast, tabela_id, onCrud, onClose]);

  const handleSubmit = useCallback(
    async (data: EditTabelaAtualizacaoFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          nome: data.nome,
          tabela_id,
        };

        await api.put('/tabela', formData);
        addToast({
          type: 'success',
          title: 'Edição Tabela',
          description: 'Tabela alterada com sucesso !',
        });
        onCrud();
        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err.response) {
          addToast({
            type: 'error',
            title: 'Erro na alteração de tabela',
            description: err.response.data.message,
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na alteração de tabela !',
          description: 'Ocorreu um erro na edição da tabela',
        });
      }
    },
    [addToast, onCrud, onClose, tabela_id],
  );

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <Container ref={overlayRef} onClick={handleOverlayClick}>
      <Content>
        <header>
          <h2>Edição de Tabela</h2>
          <FiX size={25} onClick={onClose} />
        </header>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={tabela}>
          <Input name="nome" icon={FiKey} placeholder="Nome" autoFocus />
          <div>
            <Button type="submit">Alterar</Button>
            <ButtonRed type="button" onClick={() => handleDelete()}>
              Excluir
            </ButtonRed>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default EditTabelaAtualizacao;
