import React, { useRef } from 'react';
import { FiX } from 'react-icons/fi';
import Button from '../Button';
import { Container, Content } from './styles';
import ButtonRed from '../ButtonRed';

interface AlertProps {
  mensagem: string;
  onClose: () => void;
  onConfirm: () => void;
}

const Alert: React.FC<AlertProps> = ({ mensagem, onClose, onConfirm }) => {
  const overlayRef = useRef(null);

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    if (e.target === overlayRef.current) {
      onClose();
    }
  };

  return (
    <Container ref={overlayRef} onClick={handleOverlayClick}>
      <Content>
        <header>
          <h2>Confirmação</h2>
          <FiX size={25} onClick={onClose} />
        </header>
        <h3>{mensagem}</h3>
        <div>
          <ButtonRed type="button" onClick={onConfirm}>
            Sim
          </ButtonRed>
          <Button type="button" onClick={onClose}>
            Não
          </Button>
        </div>
      </Content>
    </Container>
  );
};

export default Alert;
